/* .menu_div {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  position: relative;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
} */

.sidebar {
  position: fixed;
  top: 20;
  left: 0;
  height: 100%;
  width: 78px;
  background-color: #11101d;
  padding: 6px 14px;
  transition: all 0.5s ease;
}

.sidebar.active {
  width: 200px;
}

.sidebar .logo_content .logo {
  color: #fff;
  display: flex;
  height: 50px;
  width: 100%;
  align-items: center;
  opacity: 0;
  pointer-events: none;
}

.sidebar.active .logo_content .logo {
  opacity: 1;
  pointer-events: none;
}

.logo_content .logo i {
  font-size: 28px;
  margin-right: 5px;
}
.logo_content .logo .logo_name {
  font-size: 20px;
  font-weight: 400;
}

.sidebar #btn {
  position: absolute;
  color: #fff;
  left: 50%;
  top: 6px;
  font-size: 20px;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  transform: translateX(-50%);
}

.sidebar.active #btn {
  left: 90%;
}

.sidebar ul {
  margin-top: 20px;
}
.sidebar ul li {
  position: relative;
  height: 50px;
  width: 100%;
  margin: 0 -30px;
  list-style: none;
  line-height: 50px;
}
.sidebar ul li .tool {
  position: absolute;
  left: 122px;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  height: 35px;
  width: 122px;
  background-color: #fff;
  line-height: 35px;
  text-align: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  transition: 0s;
  opacity: 0;
  pointer-events: none;
  display: block;
}
.sidebar.active ul li .tool {
  display: none;
}
.sidebar ul li:hover .tool {
  top: 50%;
  opacity: 1;
  transition: all 0.5s ease;
}

.sidebar ul li a {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  border-radius: 12px;
  white-space: nowrap;
}
.sidebar.active ul li a:hover {
  background: #3a94ac;
}

.sidebar.active ul li a i:hover {
  background: 0;
}

.sidebar ul li a i {
  height: 38px;
  min-width: 50px;
  border-radius: 12px;
  line-height: 50px;
  text-align: center;
}

.sidebar ul li a i:hover {
  background: #3a94ac;
  transition: all 0.4s ease;
}
.sidebar .links_name {
  opacity: 0;
  pointer-events: none;
}

.sidebar.active .links_name {
  opacity: 1;
  pointer-events: auto;
}
