.contentmaster {
  width: 100%;
  height: 100%;
}

.phonediv {
  margin-top: 5%;
  margin-left: 3%;
  display: flex;
}
.phonediv2 {
  margin-top: 3%;
  margin-left: 20%;
  display: flex;
}

.txtTitle {
  color: white;
  font-size: 1.5rem;
  margin-left: 10px;
}

.inputdiv {
  margin-top: 20px;
  margin-left: 8%;
}

.codediv {
  margin-top: 10%;
  margin-left: 10%;
}
.searchbar {
  margin-top: 0%;
  margin-left: -90%;
}

.searchdiv {
  margin-top: 12%;
  margin-left: 15%;
}

.resultsdiv {
  margin-top: -60px;
}
.resultp {
  color: aqua;
  font-size: 30px;
}
.linea {
  color: white;
  font-size: 30px;
  margin-top: 0px;
}
.producto {
  color: white;
  font-size: 25px;
}

.codigo {
  color: white;
  font-size: 50px;
  text-decoration: underline;
  margin-top: -35px;
}

.cantidad {
  margin-top: 20px;
  color: yellowgreen;
  font-size: 150px;
}
