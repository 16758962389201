.inicio {
  background: #16222a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #3a6073,
    #16222a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #3a6073,
    #16222a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  /* width: 100%; */
  min-height: 100vh;
  margin: 0;
}
.hmb {
  font-size: 5rem;
  color: white;
  padding: 0;
  border-radius: 20px;
}

.headerinicio {
  padding: 20px;
}

.headerinicio em {
  font-size: 4rem;
  color: white;
  padding: 0;
  border-radius: 10px;
}

.card {
  background: white;
}

.card input {
  padding: 8px;
  margin-top: 15px;
}

.card {
  color: black;
  padding: 15px;
  margin-top: 1px;
}

.card-header h3 {
  font-size: 3rem;
}
.btninicio {
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
  /* background: rgb(119, 136, 153); */
}

.btninicio:hover {
  transform: scale(1.07);
}

.enlace {
  color: white;
}

.img {
  height: 50px;
  width: 50px;
}

.us {
  font-size: 2rem;
  border-radius: 10px;
}

.pw {
  font-size: 2rem;
  border-radius: 10px;
}

.iconos {
  display: flex;
}

.iconformat {
  margin-top: 7px;
  margin-left: -5px;
}

.input-group-text {
  background-color: transparent;
  border-color: transparent;
}
