.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 120;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position: absolute;
  width: 800px;
  max-height: 800px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -80%);
  background: #8e9eab; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #eef2f3,
    #8e9eab
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #eef2f3,
    #8e9eab
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: black;
  z-index: 0;
}

.modal-moves {
  position: absolute;
  width: 1200px;
  max-height: 800px;
  top: 10%;
  left: 17.5%;
  background: #8e9eab; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #eef2f3,
    #8e9eab
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #eef2f3,
    #8e9eab
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: black;
  z-index: 0;
}

.search-modal {
  position: absolute;
  width: 400px;
  height: 200px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -80%);
  background: #8e9eab; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #eef2f3,
    #8e9eab
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #eef2f3,
    #8e9eab
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: red;
}

/* AQUI LE DAMOS CSS AL FILTERDATA.JS PARA NO CREAR OTRO */
.filter-modal {
  position: absolute;
  min-width: 220px;
  max-width: 280px;
  max-height: 800px;
  top: 20%;
  left: 3%;
  transition: all 0.5s ease-in;
  background: #e6dada; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #274046,
    #e6dada
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #274046,
    #e6dada
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: black;
}
.modal-filter {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.1);
}

.filter-history {
  position: absolute;
  width: 250px;
  height: 380px;
  top: 40%;
  left: 90%;
  transform: translate(-50%, -65%);
  transition: all 0.5s ease-in;
  background: #e6dada; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #274046,
    #e6dada
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #274046,
    #e6dada
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: black;
}

/*  MODAL DE MI HISTORIAL PARA MOSTRAR CATEGORIAS */
.modal2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 120;
  background: rgba(0, 0, 0, 0.1);
}
.category-his-filter {
  position: absolute;
  width: 400px;
  max-height: 1000px;
  top: 12.1%;
  background: #8e9eab; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #eef2f3,
    #8e9eab
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #eef2f3,
    #8e9eab
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: black;
  z-index: 0;
  border: black 4px solid;
}
.category-button {
  display: grid;
  position: relative;
  place-items: center;
  grid-template-columns: repeat(2, 1fr);
}

/* AQUI LE DAMOS CSS AL EXCELIMPORTDATA.JS PARA NO CREAR OTRO */
.import-modal {
  position: absolute;
  width: 250px;
  max-height: 900px;
  top: 20%;
  left: 80%;
  transition: all 0.5s ease-in;
  background: #2c3e50; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #4ca1af,
    #2c3e50
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #4ca1af,
    #2c3e50
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  color: whitesmoke;
}
.exportDate-modal {
  position: absolute;
  width: 700px;
  height: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -65%);
  transition: all 0.5s ease-in;
  background: #2c3e50; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #4ca1af,
    #2c3e50
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #4ca1af,
    #2c3e50
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  color: whitesmoke;
}
.modal-filter {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.1);
}

/* AQUI SE DA FORMATO AL CSS DE SELECTBAR */
.select-modal {
  position: absolute;
  top: 38px;
  left: 67.5%;
}

.searchbtn {
  transition: display 3000ms ease;
}

/* ESTO ES CSS DE VENTANA MODAL */
.display-block {
  display: block;
}
.display-none {
  display: none;
}
.titlebtn {
  padding-top: 13px;
}
.ingresar {
  display: flex;
}
.titlemodal {
  color: red;
}
.tblmodal {
  width: 100%;
}

.tblmodal-body {
  display: flex;
}

.txtcod-modal {
  padding-left: 50px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 20px;
  padding-bottom: 10px;
}

.txtcant-modal {
  padding-left: 380px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 20px;
  padding-bottom: 10px;
}

.input-modal {
  padding-left: 50px;
}
.label-modal {
  padding-left: 410px;
  width: 52 0px;
}

.message_error {
  color: red;
  font-size: 15px;
  margin-left: 20px;
}

.phone-modal {
  position: absolute;
  width: 300px;
  height: 350px;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -65%);
  transition: all 0.5s ease-in;
  background: #e6dada; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #274046,
    #e6dada
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #274046,
    #e6dada
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: black;
}
