@import url(https://fonts.googleapis.com/css2?family=Suez+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Tangerine:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Marck+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fugaz+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Aclonica&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap);
.inicio {
  background: #16222a; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #3a6073,
    #16222a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  /* width: 100%; */
  min-height: 100vh;
  margin: 0;
}
.hmb {
  font-size: 5rem;
  color: white;
  padding: 0;
  border-radius: 20px;
}

.headerinicio {
  padding: 20px;
}

.headerinicio em {
  font-size: 4rem;
  color: white;
  padding: 0;
  border-radius: 10px;
}

.card {
  background: white;
}

.card input {
  padding: 8px;
  margin-top: 15px;
}

.card {
  color: black;
  padding: 15px;
  margin-top: 1px;
}

.card-header h3 {
  font-size: 3rem;
}
.btninicio {
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
  /* background: rgb(119, 136, 153); */
}

.btninicio:hover {
  -webkit-transform: scale(1.07);
          transform: scale(1.07);
}

.enlace {
  color: white;
}

.img {
  height: 50px;
  width: 50px;
}

.us {
  font-size: 2rem;
  border-radius: 10px;
}

.pw {
  font-size: 2rem;
  border-radius: 10px;
}

.iconos {
  display: -webkit-flex;
  display: flex;
}

.iconformat {
  margin-top: 7px;
  margin-left: -5px;
}

.input-group-text {
  background-color: transparent;
  border-color: transparent;
}

body {
  min-height: 100vh;
  background: #16222a; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #3a6073,
    #16222a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.bienvenida {
  padding: 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

/* .bienvenida {
  min-height: 100vh;
  display: grid;
  place-items: center;
  overflow: scroll;
  grid-template-columns: auto ;
  grid-gap: -90px;
} */
.mastheadbd {
  padding: 30px;
}
.mb {
  font-size: 3rem;
  color: white;
}

.mb-5 {
  font-size: 2rem;
  color: white;
  font-family: serif;
  font-family: initial;
}

.usuario {
  padding: 20px;
  color: white;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.avatar {
  margin-left: 15px;
}

.row-images {
  display: grid;
  position: relative;
  place-items: center;
  grid-template-columns: repeat(3, 1fr);
}

.images {
  margin-top: 35px;
  object-fit: contain;
  max-height: 20px;
  margin-right: 40px;
  margin-left: 40px;
  transition: -webkit-transform 450ms;
  transition: transform 450ms;
  transition: transform 450ms, -webkit-transform 450ms;
}
.images:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.imagess {
  margin-top: 10px;
  object-fit: contain;
  max-height: 20px;
  margin-right: 40px;
  margin-left: 40px;
  transition: -webkit-transform 450ms;
  transition: transform 450ms;
  transition: transform 450ms, -webkit-transform 450ms;
}
.imagess:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

/*"Rubik Mono One, sans-serif"*/ /*'Berkshire Swash', cursive*/
.bgpage {
  background: #16222a; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #3a6073,
    #16222a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  height: 100%;
  min-height: 100vh;
  max-height: 1000vh;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  overflow: hidden;
}
.botones {
  display: -webkit-flex;
  display: flex;
  padding-bottom: 5px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.btns {
  margin-left: 22px;
  display: -webkit-flex;
  display: flex;
}

.btnsO {
  margin-left: 1200px;
}
.btnsF {
  margin-left: 1382px;
}

.btnmainpage {
  position: absolute;
  right: 5px;
  margin-right: 10px;
}

.btnhistorypage {
  margin-top: 2%;
  display: -webkit-flex;
  display: flex;
  position: absolute;
  right: 5px;
  margin-right: 10px;
}

.btnMainPageAdd {
  margin-right: 90px;
}
.btnMainPage {
  margin-left: 10px;
}
.btnExcelPage {
  margin-left: 10px;
  color: white;
}
.txtNavBar {
  font-family: "Rubik Mono One", sans-serif;
  font-size: 19px;
  letter-spacing: -0.5px;
  word-spacing: -11px;
}

/* CSS DE LA PAGINA HISTORIAL */
.his_button_lineas {
  grid-template-columns: repeat(3, 1fr);
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px;
}

.divmethodpage {
  display: grid;
  position: relative;
  place-items: center;
  grid-template-columns: repeat(3, 1fr);
}

.divmethodpage2 {
  display: absolute;
}

/* DIV PARA CATEGORIAS DE HISTORIAL*/
.category-filter-his {
  margin-top: 1000;
}

.divmethod {
  display: -webkit-flex;
  display: flex;
}

.nav-item {
  background-color: #333943;
}

.nav-item ul li a {
  color: black;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  border-radius: 12px;
  white-space: nowrap;
}

.nav-item ul li a:hover {
  background: #3a94ac;
}

.nav-item2 ul li a i {
  height: 38px;
  min-width: 50px;
  border-radius: 12px;
  line-height: 50px;
  text-align: center;
}

.nav-item2 ul li a {
  color: black;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  border-radius: 12px;
  white-space: nowrap;
}

.nav-item2 ul li a:hover {
  background: #3a94ac;
}

.nav-item2 ul li a i {
  height: 38px;
  min-width: 50px;
  border-radius: 12px;
  line-height: 50px;
  text-align: center;
}

/* .menu_div {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  position: relative;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
} */

.sidebar {
  position: fixed;
  top: 20;
  left: 0;
  height: 100%;
  width: 78px;
  background-color: #11101d;
  padding: 6px 14px;
  transition: all 0.5s ease;
}

.sidebar.active {
  width: 200px;
}

.sidebar .logo_content .logo {
  color: #fff;
  display: -webkit-flex;
  display: flex;
  height: 50px;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  opacity: 0;
  pointer-events: none;
}

.sidebar.active .logo_content .logo {
  opacity: 1;
  pointer-events: none;
}

.logo_content .logo i {
  font-size: 28px;
  margin-right: 5px;
}
.logo_content .logo .logo_name {
  font-size: 20px;
  font-weight: 400;
}

.sidebar #btn {
  position: absolute;
  color: #fff;
  left: 50%;
  top: 6px;
  font-size: 20px;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.sidebar.active #btn {
  left: 90%;
}

.sidebar ul {
  margin-top: 20px;
}
.sidebar ul li {
  position: relative;
  height: 50px;
  width: 100%;
  margin: 0 -30px;
  list-style: none;
  line-height: 50px;
}
.sidebar ul li .tool {
  position: absolute;
  left: 122px;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 6px;
  height: 35px;
  width: 122px;
  background-color: #fff;
  line-height: 35px;
  text-align: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  transition: 0s;
  opacity: 0;
  pointer-events: none;
  display: block;
}
.sidebar.active ul li .tool {
  display: none;
}
.sidebar ul li:hover .tool {
  top: 50%;
  opacity: 1;
  transition: all 0.5s ease;
}

.sidebar ul li a {
  color: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  border-radius: 12px;
  white-space: nowrap;
}
.sidebar.active ul li a:hover {
  background: #3a94ac;
}

.sidebar.active ul li a i:hover {
  background: 0;
}

.sidebar ul li a i {
  height: 38px;
  min-width: 50px;
  border-radius: 12px;
  line-height: 50px;
  text-align: center;
}

.sidebar ul li a i:hover {
  background: #3a94ac;
  transition: all 0.4s ease;
}
.sidebar .links_name {
  opacity: 0;
  pointer-events: none;
}

.sidebar.active .links_name {
  opacity: 1;
  pointer-events: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 120;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position: absolute;
  width: 800px;
  max-height: 800px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -80%);
          transform: translate(-50%, -80%);
  background: #8e9eab; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #eef2f3,
    #8e9eab
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: black;
  z-index: 0;
}

.modal-moves {
  position: absolute;
  width: 1200px;
  max-height: 800px;
  top: 10%;
  left: 17.5%;
  background: #8e9eab; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #eef2f3,
    #8e9eab
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: black;
  z-index: 0;
}

.search-modal {
  position: absolute;
  width: 400px;
  height: 200px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -80%);
          transform: translate(-50%, -80%);
  background: #8e9eab; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #eef2f3,
    #8e9eab
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: red;
}

/* AQUI LE DAMOS CSS AL FILTERDATA.JS PARA NO CREAR OTRO */
.filter-modal {
  position: absolute;
  min-width: 220px;
  max-width: 280px;
  max-height: 800px;
  top: 20%;
  left: 3%;
  transition: all 0.5s ease-in;
  background: #e6dada; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #274046,
    #e6dada
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: black;
}
.modal-filter {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.1);
}

.filter-history {
  position: absolute;
  width: 250px;
  height: 380px;
  top: 40%;
  left: 90%;
  -webkit-transform: translate(-50%, -65%);
          transform: translate(-50%, -65%);
  transition: all 0.5s ease-in;
  background: #e6dada; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #274046,
    #e6dada
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: black;
}

/*  MODAL DE MI HISTORIAL PARA MOSTRAR CATEGORIAS */
.modal2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 120;
  background: rgba(0, 0, 0, 0.1);
}
.category-his-filter {
  position: absolute;
  width: 400px;
  max-height: 1000px;
  top: 12.1%;
  background: #8e9eab; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #eef2f3,
    #8e9eab
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: black;
  z-index: 0;
  border: black 4px solid;
}
.category-button {
  display: grid;
  position: relative;
  place-items: center;
  grid-template-columns: repeat(2, 1fr);
}

/* AQUI LE DAMOS CSS AL EXCELIMPORTDATA.JS PARA NO CREAR OTRO */
.import-modal {
  position: absolute;
  width: 250px;
  max-height: 900px;
  top: 20%;
  left: 80%;
  transition: all 0.5s ease-in;
  background: #2c3e50; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #4ca1af,
    #2c3e50
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  color: whitesmoke;
}
.exportDate-modal {
  position: absolute;
  width: 700px;
  height: 300px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -65%);
          transform: translate(-50%, -65%);
  transition: all 0.5s ease-in;
  background: #2c3e50; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #4ca1af,
    #2c3e50
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  color: whitesmoke;
}
.modal-filter {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.1);
}

/* AQUI SE DA FORMATO AL CSS DE SELECTBAR */
.select-modal {
  position: absolute;
  top: 38px;
  left: 67.5%;
}

.searchbtn {
  transition: display 3000ms ease;
}

/* ESTO ES CSS DE VENTANA MODAL */
.display-block {
  display: block;
}
.display-none {
  display: none;
}
.titlebtn {
  padding-top: 13px;
}
.ingresar {
  display: -webkit-flex;
  display: flex;
}
.titlemodal {
  color: red;
}
.tblmodal {
  width: 100%;
}

.tblmodal-body {
  display: -webkit-flex;
  display: flex;
}

.txtcod-modal {
  padding-left: 50px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 20px;
  padding-bottom: 10px;
}

.txtcant-modal {
  padding-left: 380px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 20px;
  padding-bottom: 10px;
}

.input-modal {
  padding-left: 50px;
}
.label-modal {
  padding-left: 410px;
  width: 52 0px;
}

.message_error {
  color: red;
  font-size: 15px;
  margin-left: 20px;
}

.phone-modal {
  position: absolute;
  width: 300px;
  height: 350px;
  top: 54%;
  left: 50%;
  -webkit-transform: translate(-50%, -65%);
          transform: translate(-50%, -65%);
  transition: all 0.5s ease-in;
  background: #e6dada; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #274046,
    #e6dada
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: black;
}

* {
  margin: 0px;
  padding: 0px;
}

.visible {
  display: block;
}
.no-visible {
  display: none;
}

.method {
  display: -webkit-flex;
  display: flex;
}

.method li {
  list-style-type: none;
}

.menu-category {
  background-color: rgb(53, 55, 61);
  width: 200px;
  margin: 0 0 auto 0;
  height: 50vh;
  border: black 1px solid;
}

.category h3 {
  font-size: 20px;
  line-height: 34px;
  padding: 0 10px;
  margin: 0px;
  margin-left: -32px;
  background: #434343;
  color: white;
  cursor: pointer;
}
.category ul {
  margin-left: -67px;
  background-color: white;
}

.category ul li a {
  color: black;
  text-decoration: none;
  font-size: 15px;
  line-height: 27px;
  display: block;
  padding: 0 15px;
}

.category ul li a:hover {
  background-color: #737373;
  border-left: 5px solid lightblue;
  transition: all 0.45s;
  color: white;
}

.columDiv {
  display: grid;
  position: -webkit-sticky;
  position: sticky;
  place-items: center;
  grid-template-columns: repeat(2, 1fr);
}
.pickers {
  text-align: center;
}

.columDiv2 {
  display: grid;
  position: -webkit-sticky;
  position: sticky;
  place-items: center;
  grid-template-columns: repeat(2, 1fr);
  z-index: 0;
}

.btnDiv {
  margin-top: 40px;
}

.btnDiv2 {
  margin-top: 0px;
}

.columnDateHistory {
  display: grid;
  position: -webkit-sticky;
  position: sticky;
  place-items: center;
  grid-template-columns: repeat(3, 1fr);
}

.contentmaster {
  width: 100%;
  height: 100%;
}

.phonediv {
  margin-top: 5%;
  margin-left: 3%;
  display: -webkit-flex;
  display: flex;
}
.phonediv2 {
  margin-top: 3%;
  margin-left: 20%;
  display: -webkit-flex;
  display: flex;
}

.txtTitle {
  color: white;
  font-size: 1.5rem;
  margin-left: 10px;
}

.inputdiv {
  margin-top: 20px;
  margin-left: 8%;
}

.codediv {
  margin-top: 10%;
  margin-left: 10%;
}
.searchbar {
  margin-top: 0%;
  margin-left: -90%;
}

.searchdiv {
  margin-top: 12%;
  margin-left: 15%;
}

.resultsdiv {
  margin-top: -60px;
}
.resultp {
  color: aqua;
  font-size: 30px;
}
.linea {
  color: white;
  font-size: 30px;
  margin-top: 0px;
}
.producto {
  color: white;
  font-size: 25px;
}

.codigo {
  color: white;
  font-size: 50px;
  text-decoration: underline;
  margin-top: -35px;
}

.cantidad {
  margin-top: 20px;
  color: yellowgreen;
  font-size: 150px;
}

