@import url("https://fonts.googleapis.com/css2?family=Suez+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tangerine:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Marck+Script&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fugaz+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Aclonica&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap"); /*"Rubik Mono One, sans-serif"*/
@import url("https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap"); /*'Berkshire Swash', cursive*/
.bgpage {
  background: #16222a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #3a6073,
    #16222a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #3a6073,
    #16222a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  height: 100%;
  min-height: 100vh;
  max-height: 1000vh;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.botones {
  display: flex;
  padding-bottom: 5px;
  justify-content: space-between;
}

.btns {
  margin-left: 22px;
  display: flex;
}

.btnsO {
  margin-left: 1200px;
}
.btnsF {
  margin-left: 1382px;
}

.btnmainpage {
  position: absolute;
  right: 5px;
  margin-right: 10px;
}

.btnhistorypage {
  margin-top: 2%;
  display: flex;
  position: absolute;
  right: 5px;
  margin-right: 10px;
}

.btnMainPageAdd {
  margin-right: 90px;
}
.btnMainPage {
  margin-left: 10px;
}
.btnExcelPage {
  margin-left: 10px;
  color: white;
}
.txtNavBar {
  font-family: "Rubik Mono One", sans-serif;
  font-size: 19px;
  letter-spacing: -0.5px;
  word-spacing: -11px;
}

/* CSS DE LA PAGINA HISTORIAL */
.his_button_lineas {
  grid-template-columns: repeat(3, 1fr);
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px;
}

.divmethodpage {
  display: grid;
  position: relative;
  place-items: center;
  grid-template-columns: repeat(3, 1fr);
}

.divmethodpage2 {
  display: absolute;
}

/* DIV PARA CATEGORIAS DE HISTORIAL*/
.category-filter-his {
  margin-top: 1000;
}

.divmethod {
  display: flex;
}

.nav-item {
  background-color: #333943;
}

.nav-item ul li a {
  color: black;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  border-radius: 12px;
  white-space: nowrap;
}

.nav-item ul li a:hover {
  background: #3a94ac;
}

.nav-item2 ul li a i {
  height: 38px;
  min-width: 50px;
  border-radius: 12px;
  line-height: 50px;
  text-align: center;
}

.nav-item2 ul li a {
  color: black;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  border-radius: 12px;
  white-space: nowrap;
}

.nav-item2 ul li a:hover {
  background: #3a94ac;
}

.nav-item2 ul li a i {
  height: 38px;
  min-width: 50px;
  border-radius: 12px;
  line-height: 50px;
  text-align: center;
}
