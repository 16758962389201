.columDiv {
  display: grid;
  position: sticky;
  place-items: center;
  grid-template-columns: repeat(2, 1fr);
}
.pickers {
  text-align: center;
}

.columDiv2 {
  display: grid;
  position: sticky;
  place-items: center;
  grid-template-columns: repeat(2, 1fr);
  z-index: 0;
}

.btnDiv {
  margin-top: 40px;
}

.btnDiv2 {
  margin-top: 0px;
}

.columnDateHistory {
  display: grid;
  position: sticky;
  place-items: center;
  grid-template-columns: repeat(3, 1fr);
}
