* {
  margin: 0px;
  padding: 0px;
}

.visible {
  display: block;
}
.no-visible {
  display: none;
}

.method {
  display: flex;
}

.method li {
  list-style-type: none;
}

.menu-category {
  background-color: rgb(53, 55, 61);
  width: 200px;
  margin: 0 0 auto 0;
  height: 50vh;
  border: black 1px solid;
}

.category h3 {
  font-size: 20px;
  line-height: 34px;
  padding: 0 10px;
  margin: 0px;
  margin-left: -32px;
  background: #434343;
  color: white;
  cursor: pointer;
}
.category ul {
  margin-left: -67px;
  background-color: white;
}

.category ul li a {
  color: black;
  text-decoration: none;
  font-size: 15px;
  line-height: 27px;
  display: block;
  padding: 0 15px;
}

.category ul li a:hover {
  background-color: #737373;
  border-left: 5px solid lightblue;
  transition: all 0.45s;
  color: white;
}
