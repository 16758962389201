body {
  min-height: 100vh;
  background: #16222a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #3a6073,
    #16222a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #3a6073,
    #16222a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.bienvenida {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* .bienvenida {
  min-height: 100vh;
  display: grid;
  place-items: center;
  overflow: scroll;
  grid-template-columns: auto ;
  grid-gap: -90px;
} */
.mastheadbd {
  padding: 30px;
}
.mb {
  font-size: 3rem;
  color: white;
}

.mb-5 {
  font-size: 2rem;
  color: white;
  font-family: initial;
}

.usuario {
  padding: 20px;
  color: white;
  align-items: center;
  display: flex;
  flex-direction: row;
}
.avatar {
  margin-left: 15px;
}

.row-images {
  display: grid;
  position: relative;
  place-items: center;
  grid-template-columns: repeat(3, 1fr);
}

.images {
  margin-top: 35px;
  object-fit: contain;
  max-height: 20px;
  margin-right: 40px;
  margin-left: 40px;
  transition: transform 450ms;
}
.images:hover {
  transform: scale(1.1);
}

.imagess {
  margin-top: 10px;
  object-fit: contain;
  max-height: 20px;
  margin-right: 40px;
  margin-left: 40px;
  transition: transform 450ms;
}
.imagess:hover {
  transform: scale(1.1);
}
